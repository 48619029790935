<script>
export default {
  name: "lemonMessageVoice",
  inheritAttrs: false,
  inject: ["IMUI"],
  render() {
    return (
      <lemon-message-basic
        class="lemon-message-voice"
        props={{ ...this.$attrs }}
        scopedSlots={{ 
          content: props => {
            {/* const content = this.IMUI.emojiNameToImage(props.content); */}
            return <div>
              <div id={props.id} class="jp-jplayer"></div>
              {/* <button id={"test"+props.id}>测试</button> */}
              <div id={"jp_container_"+props.id} class="jp-audio " role="application" aria-label="media player">
                <div class='el-row'>
                  <div class="el-col el-col-24">
                    <div class="jp-details" style="font-size: 18px;">
                              <div class="jp-title" aria-label="title">&nbsp;</div>
                          </div>
                    <div class='el-row'>
                          <div class="el-col el-col-24" style="text-align: center; margin-bottom:15px">
                              <img id={'img_'+props.id} class="rotating_element" src={props.content[0].img!=undefined?props.content[0].img:'/static/imgs/heijiao.jpg'} style="height:100px;width:100px;border-radius: 50%;margin-top: 10px; "/>
                          </div>
                        </div>
                     
                      
                  </div>
                </div>
                <div class='el-row'>
                  <div class="el-col el-col-24" >
                      <div class="jp-type-single">
                          <div class='el-row'>
                              <div class="el-col el-col-4" style="text-align: center;">
                                  <div class="jp-controls">
                                      <button class="jp-loop-state" role="button" tabindex="0" id={'loop_'+props.id}>顺序播放</button>
                                      {/* <button class="jp-loop-one" role="button" tabindex="0" v-else-if="true==false" >单曲循环</button>
                                      <button class="jp-loop-repeat" role="button" tabindex="0" v-else-if="true==false" >随机播放</button> */}
                                  </div>
                              </div>
                              <div class="el-col el-col-16" >
                                <div class='el-row'>
                                    <div class="el-col el-col-8" type="flex" align="middle">
                                          <div class="jp-controls">
                                              <button class="jp-previous" role="button" tabindex="0" id={'pre_'+props.id}>previous</button>
                                          </div>
                                      </div>
                                      <div class="el-col el-col-8" type="flex" align="middle">
                                          <div class="jp-controls">
                                              <button class="jp-play" role="button" tabindex="0">play</button>
                                          </div>
                                      </div>
                                      <div class="el-col el-col-8" type="flex" align="middle">
                                          <div class="jp-controls">
                                              <button class="jp-next" role="button" tabindex="0" id={'next_'+props.id}>next</button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="el-col el-col-4" style="text-align: center;">
                                  <div class="jp-controls">
                                      <button class="jp-mlist" role="button" tabindex="0" id={'showmusiclist_'+props.id}>list</button>
                                  </div>
                              </div>
                            </div>
                          
                          <div class='el-row'>
                            <div class='el-col el-col-16'>
                              <div class="jp-gui jp-interface">
                              <div class="jp-progress">
                                  <div class="jp-seek-bar">
                                      <div class="jp-play-bar"></div>
                                  </div>
                              </div>
                              
                              <div class="jp-time-holder">
                                  <div class="jp-current-time" role="timer" aria-label="time">&nbsp;</div>
                                  <div class="jp-duration" role="timer" aria-label="duration">&nbsp;</div>
                              </div>
                            </div>
                          </div>
                          <div class='el-col el-col-8'>
                            <div class="jp-volume-controls" style="width:100%">
                                  <button class="jp-mute" role="button" tabindex="0">mute</button>
                                  <button class="jp-volume-max" role="button" tabindex="0">max volume</button>
                                  <div class="jp-volume-bar">
                                      <div class="jp-volume-bar-value"></div>
                                  </div>
                              </div>
                          </div>
                        </div> 
                          
                          
                          <div class="jp-no-solution">
                              <span>Update Required</span>
                              To play the media you will need to either update your browser to a recent version or update your <a href="http://get.adobe.com/flashplayer/" target="_blank">Flash plugin</a>.
                          </div>
                      </div>
                  </div>
                </div>
                <div id={'musiclist_'+props.id} style="height: 200px;overflow-y: scroll;display:none">
                </div>
              </div>
            </div>;
            
          }
        }}
      />
    );
  },
  mounted(){
    var showlist=false
    var looptag=0
    var musictag=0
    var message=this.$attrs.message
    // message.content='https://cdn2.ear0.com:3321/preview?soundid=35496&type=mp3'
    var musicdata=message.content
    var stream = {
      title: musicdata[0].title,
      mp3: musicdata[0].url
    }
    var ready = false;
    $('#'+message.id).jPlayer({
    ready: function (event) {
      ready = true;
      $(this).jPlayer("setMedia", stream);
    },
    play: function() { // To avoid multiple jPlayers playing together.
			$(this).jPlayer("pauseOthers");
		},
		swfPath: "../../dist/jplayer",
		supplied: "mp3",
    cssSelectorAncestor: "#jp_container_"+message.id,
		preload: "none",
		wmode: "window",
		useStateClassSkin: true,
		autoBlur: false,
		keyEnabled: false,
	});
  // 上一首方法
   function premusic(){
      if (musictag==0){
          musictag=musicdata.length-1
      }else{
          musictag=musictag-1
      }
      play()
  }

// 下一首方法
  function nextmusic(){
    if (musictag==musicdata.length-1){
        musictag=0
    }else{
        musictag=musictag+1
    }
    play()
  }

  // 播放方法
  function bofang(){
    if(looptag==0){
          nextmusic()
      }else if (looptag==1){
          try{
            $('#'+message.id).jPlayer('play')
          }catch(e){
            pass
          }
          
      }else if(looptag==2){
          musictag=Math.floor(Math.random()*(musicdata.length))
          play() 
      }
  }
  
  function play(){
    var data={
        title: musicdata[musictag].title,
        url: musicdata[musictag].url // Valid link helps see behaviour
    }
    try{
      $('#musiclist_'+message.id).find('img').removeClass()
      $('#'+musictag).find('img').addClass('rotating_element')
      toscroll(musictag)
      $('#'+message.id).jPlayer("setMedia", {
          title: data.title,
          mp3: data.url // Valid link helps see behaviour
      });
      // $("#jquery_jplayer_1 .jp-title").html("<li>"+data.title+"</li>");
      // $('#'+message.id).jPlayer('pause')
      $('#'+message.id).jPlayer('play')
      
      $('#musiclist_'+message.id).find('img').removeClass()
      $('#'+message.id+'_'+musictag).find('img').addClass('rotating_element')
      $("#img_"+message.id).attr("src",  musicdata[musictag].img);
    }
    catch(e){
      pass
    }

  }
  // 切换循环方法
  $('#loop_'+message.id).on('click',(function(){
    $('#loop_'+message.id).removeClass()
    if(looptag==2){
        looptag=0
        $('#loop_'+message.id).addClass("jp-loop-state")
    }else{
        looptag=looptag+1
        if(looptag==1){
          $('#loop_'+message.id).addClass("jp-loop-one")
        }else if(looptag==2){
          $('#loop_'+message.id).addClass("jp-loop-repeat")
        }
    }
  }))
  // 绑定音频结束
  $('#'+message.id).bind($.jPlayer.event.ended, function(event) {
			bofang()
	});

// 下一曲
$('#next_'+message.id).on('click',(function(){
    nextmusic()
  }))
// 上一曲
$('#pre_'+message.id).on('click',(function(){
    premusic()
  }))

// 初始化列表数据
function showdata(){
  for (var i=0;i<musicdata.length;i++){
      var html='<div class="el-row" style="cursor: pointer;white-space:normal !important" id="'+message.id+"_"+i+'" class="muscilist" >\
                    <div style="white-space:normal !important">\
                        <div class="el-col el-col-6" style="white-space:normal !important">\
                            <img  src='+(musicdata[i].img!=undefined?musicdata[i].img:'/static/imgs/heijiao.jpg')+' style="height:70px !important;width:70px;border-radius: 50%;margin-top: 10px;margin-left: 20px;min-height:70px !important "/>\
                        </div>\
                        <div class="el-col el-col-18" style="white-space:normal !important">\
                            <div style="font-size: 20px;padding-left: 20px;padding-top: 20px;white-space:normal !important">'+musicdata[i].title+'</div>\
                            <div style="font-size: 15px;padding-left: 20px;white-space:normal !important">'+(musicdata[i].des!=undefined?musicdata[i].des:'')+'</div>\
                        </div>\
                    </div>\
                </div><hr>'
      $('#musiclist_'+message.id).append(html)
      $('#'+message.id+'_'+i).on('click',(function(){
        musictag=parseInt(this.id.split('_')[1])
        $('#musiclist_'+message.id).find('img').removeClass()
        $('#'+message.id+'_'+musictag).find('img').addClass('rotating_element')
        play()
      }))
    }
}

  // 列表打开关闭按钮
  document.getElementById('showmusiclist_'+message.id).addEventListener('click',function(){
    if (showlist==true){
      showlist=false
      $("#musiclist_"+message.id).hide()
    }else{
      showlist=true
      $("#musiclist_"+message.id).show()
      toscroll(musictag)
    }
    
  })

  // 跳转到列表指定元素
  function toscroll(id){
    setTimeout(function(){
        var element = document.getElementById(message.id+'_'+id)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    },500)
  }
  
  // 数据初始化
  showdata()
  }
};
</script>
<style lang="stylus">
@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
	}
	
	.rotating_element {
		animation: rotate 8s linear infinite;
	}
    
</style> 
