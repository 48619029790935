<template>
  <div class="home">
    <audio id='tishi' src="https://www.ogoai.com/back/static/webchat_fixed/info.mp3"></audio>
    <!-- 格式转换 -->
    <FormatChange v-if="viewdata.geshiVisible==true" :userid="userid" @child-event="geshiwork" @close-event="viewclose"></FormatChange>
    <!-- 配音 -->
    <Peiyin v-if="viewdata.peiyinVisible==true" :userid="userid" @child-event="peiyinwork" @close-event="viewclose"></Peiyin>
    <!-- 电台 -->
    <Diantai v-if="viewdata.diantaiVisible==true" :userid="userid" @child-event="diantaiwork" @close-event="viewclose"></Diantai>
    <!-- 文档翻译 -->
    <TransOffice v-if="viewdata.transofficeVisible==true" :userid="userid" @child-event="transofficework" @close-event="viewclose"></TransOffice>
    <!-- 图片预览 -->
    <el-image id="elimg"
      style="width: 100px; height: 100px;display: none;"
      :src="imgurl" 
      :preview-src-list="imgsrcList">
    </el-image>
     <!-- 聊天组件 -->    
    <lemon-imui  
      style="overflow: hidden;"
      v-loading="loading"
      element-loading-text="正在连接"
      ref="IMUI"
      :theme="theme"
      :user="user"
      :width=lemonui_width
      :height=lemonui_height
      @pull-messages='handlePullMessages'
      @send="handleSend"
      @message-click="messageclick"
      :sendKey="sendKey"
      simple
    >
     <!-- 聊天框底部 -->
    <template #editor-footer>
      <div style="text-align: center;">
        <span>使用 Enter 键可进行消息发送</span>
        <div><a href="http://www.ogoai.com" target="_blank">OGOAI</a>提供技术支持</div>
      </div>
    </template>
    <template #message-side> 
    </template>
     <!-- 聊天框标题 -->
    <template #message-title>
      <div style="float: left;">
        <div v-if="voice==false" @click="changeVoice">
          <Icon size="20" color="#257b98" >
            <VolumeMute @click="changeVoice"></VolumeMute>
          </Icon>
        </div>
        <div  v-else-if="voice==true"  @click="changeVoice">
          <Icon size="20" color="#257b98">
          <VolumeMediumSharp @click="changeVoice"></VolumeMediumSharp>
        </Icon>
        </div>
       
      </div>
      <div style="float: right;">
        
        <el-button size="mini" type="primary" round @click="login" v-if="user.isvisitor ==true">登录</el-button>
        <el-button size="mini" round v-else @click="userinfo" type="success">个人中心</el-button>
        <el-button size="mini" round @click="loginout()" v-if="user.isvisitor==false" type="warning">登出</el-button>        <br />
      </div>
    </template>
    <!-- 聊天框消息 -->
    <template #message-after="message">
        <el-button type="text"  v-if="message.type=='file'&&message.fromUser.id!=user.id" @click="downfile(message)">下载</el-button>
        <el-button type="text"  v-if="message.type=='file'&&message.fromUser.id!=user.id" @click="yulan(message)" style="margin-left: 0px !important;">预览</el-button>
        <el-button type="text"  v-if="message.type=='nfile'&&message.fromUser.id!=user.id" @click="downfile(message)">下载</el-button>
        <el-button type="text"  v-if="message.type=='nfile'&&message.fromUser.id!=user.id" @click="yulan(message)" style="margin-left: 0px !important;">预览</el-button>
    </template>
    </lemon-imui>
    <!-- 功能列表抽屉 -->
    <el-drawer
      title="功能列表"
      :visible.sync="functiondrawer"
      :direction="direction"
      size="40%"
      >
      <span slot="title" class="dialog-footer">
        <span style="font-size: 14px;">功能列表</span>
      </span>
      <div v-for="func in functionlist" :key="func.mainname">
        <el-row >
          <el-divider content-position="left">{{ func.mainname }}</el-divider>
          <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="4" style="text-align: center; margin-bottom: 15px;" v-for="item in func.list" :key="item.name"> <el-button size="mini" @click="sendfun(item.name)"> <i :class=item.icon></i> {{item.name}} </el-button> </el-col>
      </el-row>
      </div>
    </el-drawer>
     <!-- 工具栏抽屉 -->
    <el-drawer
      title="选择工具栏功能"
      :visible.sync="toolsdrawer"
      :direction="direction"
      size="40%"
      >
      <span slot="title" class="dialog-footer">
        <span style="font-size: 14px;">设置工具栏</span>
      </span>
      <el-checkbox-group v-model="toolsidlist" @change="toolschange">
      <div v-for="func in functionlist" :key="func.mainname">
        <el-row >
          <el-divider content-position="left">{{ func.mainname }}</el-divider>
          <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="4" style="text-align: center; margin-bottom: 15px;" v-for="item in func.list" :key="item.name">
            <el-checkbox :label="item.id">{{ item.name }}</el-checkbox>
          </el-col>
      </el-row>
      </div>
    </el-checkbox-group>
    </el-drawer>
     <!-- 个人中心 -->
    <el-dialog
      title="个人中心"
      :visible.sync="userdrawer"
      width="80%"
      >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="头像">
          <img :src="user.avatar" style="width: 50px;height: 50px;"/>
        </el-form-item>
        <el-form-item label="用户名称">
          <span>{{ user.displayName }}</span>
        </el-form-item>
        <el-form-item label="是否为会员" label-width="100px">
          <span>{{ user.isvip==true?'是':'否' }}</span>
        </el-form-item>
        <el-form-item label="IP地址">
          <span>{{ user.userip }}</span>
        </el-form-item>
        <el-form-item label="地区">
          <span>{{ user.address }}</span>
        </el-form-item>
        <el-form-item label="会员信息" v-if="user.isvip==true">
          <span>{{ user.vipinfo }}</span>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="loginout();userdrawer=false">登出</el-button>
          <el-button @click="userdrawer=false">关闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    
  </div>
</template>

<script>
import Vue from "vue";
import { VolumeMute,VolumeMediumSharp } from '@v2icons/ionicons5'
import { Icon } from '@v2icons/utils'
import LemonMessageText from "../components/lemon-message-text.vue";
import LemonMessageIframe from "../components/lemon-message-iframe.vue";
import LemonMessageVideo from "../components/lemon-message-video.vue";
import LemonMessageVoice from "../components/lemon-message-voice.vue";
import LemonMessageLive from "../components/lemon-message-live.vue";
import LemonMessageNfile from "../components/lemon-message-nfile.vue";
import FormatChange from '../components/formatchange.vue'
import Peiyin from '../components/peiyin.vue'
import Diantai from '../components/diantai.vue'
import TransOffice from '../components/transoffice.vue'
Vue.component(LemonMessageText.name, LemonMessageText);
Vue.component(LemonMessageIframe.name, LemonMessageIframe);
Vue.component(LemonMessageVideo.name, LemonMessageVideo);
Vue.component(LemonMessageVoice.name, LemonMessageVoice);
Vue.component(LemonMessageLive.name, LemonMessageLive);
Vue.component(LemonMessageNfile.name, LemonMessageNfile);
Vue.component(LemonMessageText.name, LemonMessageText);
export default {
  name: 'cusTomer',
  components: {
    FormatChange,
    TransOffice,
    Peiyin,
    Diantai,
    Icon,
    VolumeMute,
    VolumeMediumSharp
  },
  data(){
      return{
        form: {
          name: '',
          region: '',
          date1: '',
          date2: '',
          delivery: false,
          type: [],
          resource: '',
          desc: '',
        },
        messageids:[],
        functiondrawer: false,// 功能抽屉显示标记
        toolsdrawer:false, //工具栏调整抽屉显示标记
        userdrawer:false,//个人中心显示标记
        direction: 'rtl',//功能抽屉显示位置
        toolsidlist:[],  //工具栏id
        functionlist:[],// 功能列表
        chatcontent:'',   //流式消息内容
        viewdata:{
          'geshiVisible':false,   //格式转换显示标记
          'peiyinVisible':false,  //配音显示标记
          'diantaiVisible':false, //电台显示标记 
          'transofficeVisible':false //文档翻译显示标记
        },
        nowview:'',      //当前显示的内容
        filterdata:[],
        loading:true, //等待连接
        theme:'blue', //主题

        siteid:'',    //站点ID
        agent:'',     //浏览器信息
        socketurl:this.$wshost+'/im_server/111/123/',      //websocket连接地址
        user:{id:1,displayName:'游客',avatar:require('../assets/touxiang.png')},  //用户信息
        kefu:{id: 2,displayName: 'AI智能助手',avatar:'https://p.qqan.com/up/2021-1/20211211131598147.jpg'},  //客服信息
        webs:'',     //websocket实例
        userid:'',   //用户ID
        filetag:'',
        imgurl: 'https://www.ogoai.com/assets/logo-Bu5P0--E.png',  //预览图片地址,占位用
        imgsrcList: [
          'https://www.ogoai.com/assets/logo-Bu5P0--E.png',
        ],          //预览图片
        elimg:null,  //预览图片元素
        videoplay:null,  //预览视频元素
        lemonui_width:window.innerWidth,  //聊天窗体宽度
        lemonui_height:window.innerHeight,//聊天窗体高度

        tishi:null,           //提示音组件
        hasInteracted:false,  //是否有交互
        
        geshivalue:'pdf', //格式转换默认格式
        transofficevalue:'', //文档翻译默认值

        page:1,
        voice:localStorage.getItem('voice')==undefined || localStorage.getItem('voice')=='true'?true:false,

        _IMUI:null,
        usereditorlist:[
          {
            name: "uploadFile",
          },
          {
            name: "uploadImage",
          },
          {
            name: "more",
            isRight: true,
            title: "更多功能",
            click: () => {
              this.functiondrawer=true;
            },
            render: () => {
              return <b>更多功能</b>;
            },
          },
        ],
        visitoreditorlist:[
          {
            name: "uploadFile",
          },
          {
            name: "uploadImage",
          },
          {
            name: "more",
            isRight: true,
            title: "更多功能",
            click: () => {
              this.functiondrawer=true;
            },
            render: () => {
              return <b>更多功能</b>;
            },
          },
        ]
      }
  },
  mounted(){
    const { IMUI } = this.$refs;
    this.tishi=document.getElementById('tishi')
    var _this = this
    this._IMUI=IMUI
    this.siteid=this.$route.params.siteid  //获取当前站点ID
    this.agent=window.navigator.userAgent  //获取当前浏览器信息
    this.getdata()
    this.interactedCheck()
    //根据窗口大小调整聊天窗体
    window.onresize = () => {
      return (() => {
        _this.lemonui_width=window.innerWidth
        _this.lemonui_height=window.innerHeight
        _this.$forceUpdate();
      })()
    }
    window.sendmess=this.sendmess
    window.imgpreclick=this.imgpreclick
   
  },
  methods:{
    addmessagework(){
      // 假设我们要绑定点击事件的类名为 'my-class'
      var elements = document.getElementsByClassName("message");
      var _that=this
      for (let i = 0; i < elements.length; i++) {
        // _that.messageids.push(id)
            elements[i].addEventListener('click', w_messageclick);
      }
    },
    addimgwork(){
      // 假设我们要绑定点击事件的类名为 'my-class'
      var elements = document.getElementsByClassName('_img');
      var _that=this
      for (let i = 0; i < elements.length; i++) {
        // _that.messageids.push(id)
            elements[i].addEventListener('click', w_imgclick);
      }
    },
    imgpreclick(src){
      this.imgsrcList=[src]
      document.getElementById('elimg').click()
    },
    changeVoice(){
      this.voice=!this.voice
      localStorage.setItem('voice',this.voice)
    },
    interactedCheck(){
      document.addEventListener('monsedown',()=>{
      this.hasInteracted=true
      })
      document.addEventListener('click',()=>{
      this.hasInteracted=true
      })
      document.addEventListener('keydown',()=>{
        this.hasInteracted=true
      })
      document.addEventListener('touchstart',()=>{
        this.hasInteracted=true
      })
    },
    userinfo(){
      this.userdrawer=true
    },

    //获取数据
    getdata(){
      this.getsiteinfo()    //获取站点信息
      this.checklogin()     //检测登录信息
      this.funclist()       //获取功能列表信息
      this.funtools()       //获取工具栏
    },
    //功能列表点击
    sendfun(tag){
        this.functiondrawer=false
        var sendinfo=this.filterdata[tag]
        if (sendinfo.filetag!=''){
          this.filetag=sendinfo.filetag
        }
        if (sendinfo.doview==1){
          setTimeout(() => {
            this.nowview=sendinfo.viewtag
            this.viewdata[sendinfo.viewtag]=true
          }, 500);
        }else{
          const message = this.generateMessage(tag);
          this.webs.send(JSON.stringify(message))
        }
    },
    //获取工具栏
    funtools(){
      var that=this
      this.$axios({
        url:'/apis/api/webchat/getfuntools/?siteid='+this.siteid,
        method:'get',
        headers:{Authorization:localStorage.getItem('token') || ''},
      })
      .then(function (response) {
         var data=response.data.data
         that.toolsidlist=[]
         var tools=[]
         if (that.user.isvisitor==true){
          tools= [...that.visitoreditorlist]
         }else{
          tools= [...that.usereditorlist]
          tools.push(
            {
            name: "settools",
            isRight: true,
            click: () => {
              that.toolsdrawer=true;
            },
            render: () => {
              return <span v-if="true">调整工具栏</span>;
            },
          }
          )
         }
         
         for (let i=0;i<data.length;i++){
            let name=data[i].name
            let id=data[i].id
            that.toolsidlist.push(id)
            var toolitem={
              name: name,
              click: () => {
                that.sendfun(data[i].trigger)
              },
              render: () => {
                return <span>{ name }</span>;
              }
            }
            tools.push(toolitem)
         }
         setTimeout(() => {
          that._IMUI.initEditorTools(tools);
         }, 1000);
      })
      .catch(function (error) {
        console.log('funtools_error')
      });
    },
    //修改工具栏
    toolschange(value){
      var _that=this
      var ids=this.toolsidlist.join(',')
      var siteid=this.siteid
      this.$axios({
        method:'post',
        headers:{Authorization:localStorage.getItem('token') || '','X-CSRFToken':this.getCookie('csrftoken')},
        url:'/apis/api/webchat/setfuntools/',
        data:{ids:ids,siteid:siteid}
      })
      .then(function (response) {
          var data=response.data
          if (data.code==200){
            _that.funtools()
          }else{
            console.log('setfuntools_error')
          }
      })
      .catch(function (error) {
        console.log('setfuntools_error')
      });
    },
    //获取功能列表
    funclist(){
      var _that=this
      this.$axios.get('/apis/api/webchat/funclist/?siteid='+this.siteid)
      .then(function (response) {
          _that.functionlist=response.data.data.fenleidata
          _that.filterdata=response.data.data.filterdata
      })
      .catch(function (error) {
        console.log('funclist_error')
      });
    },
    //数据发送
    senddata(tag){
      this.sendmess(tag)
      this.functiondrawer=false
    },
    // 获取当前时间
    getTime(){
      return new Date().getTime();
    },
    //创建随机字符串
    generateRandId(){
       return Math.random()
      .toString(36)
      .substr(-8);
    },

    generateMessage(content = ""){
        return {
          id: this.generateRandId(),
          status: "succeed",
          type: "text",
          sendTime: this.getTime(),
          content: content,
          //fileSize: 1231,
          //fileName: "asdasd.doc",
          toContactId:this.siteid,
          fromUser:this.user,
        };
    },
    generateMessageKefu(content = ""){
        return {
          id: this.generateRandId(),
          status: "succeed",
          type: "text",
          sendTime: this.getTime(),
          content: content,
          //fileSize: 1231,
          //fileName: "asdasd.doc",
          toContactId:this.siteid,
          fromUser:this.kefu,
        };
    },
    viewclose(){
      this.viewdata[this.nowview]=false
      this.filetag=''
    },
    updateObjectValue(obj, newValue) {
      return { ...obj, value: newValue };
    },
    //文档格式转换交互文字
    geshiwork(value){
      this.sendmessfromkefu('你希望将文档转换为'+value+',请发送需要转换的文档！')
      const message = this.generateMessage('格式转换')
      this.webs.send(JSON.stringify(message))
      this.viewdata[this.nowview]=false
    },
    //文档翻译交互文字
    transofficework(value){
      this.sendmessfromkefu('你希望将文档从'+value.fromlabel+'转换为'+value.tolabel+',请发送需要转换的文档！')
      const message = this.generateMessage('文档翻译')
      this.webs.send(JSON.stringify(message))
      this.viewdata[this.nowview]=false
    },
    // 配音交互
    peiyinwork(){
      const message = this.generateMessage('配音')
      this.webs.send(JSON.stringify(message))
      this.viewdata[this.nowview]=false
    },
    // 电台交互
    diantaiwork(){
      const message = this.generateMessage('电台')
      this.webs.send(JSON.stringify(message))
      this.viewdata[this.nowview]=false
    },
    //发送信息给后台
    sendmess(value){
      const message = this.generateMessage(value);
      this._IMUI.appendMessage(message, true);
      this.webs.send(JSON.stringify(message)) 
    },
    //从客服处发送信息，不经过后台
    sendmessfromkefu(value){
      const message = this.generateMessageKefu(value);
      this._IMUI.appendMessage(message, true);
    },
    //获取站点信息
    getsiteinfo(){
      var that=this
      this.$axios.get('/apis/api/webchat/getsitefromid/?siteid='+this.siteid)
      .then(function (response) {
          var data=response.data
          if (data.code==200){
            that.kefu.displayName= data.data.kefuname
            that.kefu.avatar= data.data.kefuavatar
            that.kefu.id=data.data.siteid          
            const contacts = [ that.kefu];
            that._IMUI.initContacts(contacts);
            that._IMUI.changeContact(that.siteid);
          }else{
            console.log('getsitefromid_error')
          }
      })
      .catch(function (error) {
        console.log('getsitefromid_error')
      });
    },
    visitoroffline(){
      this.$axios({
        method:'post',
        headers:{'X-CSRFToken':this.getCookie('csrftoken')},
        url:'/apis/api/webchat/offline/',
        data:{siteid:this.siteid,visitagent:this.agent}
      })
      .then(function (response) {
          var data=response.data
      })
      .catch(function (error) {
        console.log('visitoroffline_error')
      });
    },
    useroffline(){
      this.$axios({
        method:'post',
        headers:{Authorization:localStorage.getItem('token') || '','X-CSRFToken':this.getCookie('csrftoken')},
        url:'/apis/api/webchat/offline/',
        data:{siteid:this.siteid,visitagent:this.agent}
      })
      .then(function (response) {
          var data=response.data
      })
      .catch(function (error) {
        console.log('useroffline_error')
      });
    },
    //登录
    login(){
      const message = this.generateMessage('登录');
      this.webs.send(JSON.stringify(message))
    },
    getCookie (name) {
      var value = '; ' + document.cookie
      var parts = value.split('; ' + name + '=')
      if (parts.length === 2) return parts.pop().split(';').shift()
    },
    //检测用户登录信息
    checklogin(){
      var _that=this
      this.$axios({
        method:'POST',
        headers:{Authorization:localStorage.getItem('token') || '','X-CSRFToken':this.getCookie('csrftoken')},
        url:'/apis/api/webchat/checklogin/',
        data:{siteid:this.siteid,visitagent:this.agent}
      })
      .then(function (response) {
          var data=response.data
          if (data.code==200){
            _that.socketurl=_that.$wshost+'/im_server/'+_that.siteid+'/'+data.data.userid+'/'
            _that.userid=data.data.id
            _that.user=data.data
            if (data.data.token!=undefined){
              localStorage.setItem('token',data.data.token)
            }
            _that.initWebsocket()
          }else{
            console.log('checklogin_error')
          }
      })
      .catch(function (error) {
        console.log(error)
        console.log('checklogin_error')
      });
    },
    //初始化websckets
    initWebsocket(){
      this.webs=new WebSocket(this.socketurl)
      this.webs.onopen=this.websockeopen
      this.webs.onerror=this.websockeerror
      this.webs.onmessage=this.onmessage
      this.webs.onclose=this.onclose
    },
    //消息接受
    onmessage(msg){
      if(msg.data=='heat'){
        this.webs.send('heat')
        return
      }
      var message=JSON.parse(msg.data)
      // this.filetag=message.filetag
      message['fromUser']=this.kefu
      if (message.type=='logintag'){
        this.visitoroffline()
        localStorage.setItem('token',message.content)
        this.getdata()
      }else if(message.model=='ai'){
        if(message.done==false){
          if(this.chatcontent==''){
            this.chatcontent=message.content
            this._IMUI.appendMessage(message,true);
          }else{
            this.chatcontent=this.chatcontent+message.content
            message.content=this.chatcontent
            this._IMUI.updateMessage(message);
            this._IMUI.messageViewToBottom()
          }
        }else{
            this.chatcontent=this.chatcontent+message.content
            message.content=this.chatcontent
            this._IMUI.updateMessage(message);
            this.chatcontent=''
            if (this.hasInteracted && this.voice){
              this.tishi.play()
            }
            
        }
        
      }
      else if(message.content!=''){
        if (this.hasInteracted  && this.voice){
          this.tishi.play()
        }
        var _that=this
        this._IMUI.appendMessage(message,true);
        setTimeout(function(){
          _that.addmessagework()
          _that.addimgwork()
        },1000)
        
      }    
    },
    websockeopen(msg){
      this.loading=false
    },
    websockeerror(msg){
      console.log('WebSocket 发生错误，尝试重连...');
      this.loading=true
      this.reconnect()
    },

    onclose(msg){
      console.log('WebSocket 连接断开，尝试重连...');
      this.loading=true
      this.reconnect()
    },
    loginout(){
      this.useroffline()
      // document.cookie='logincookie' + "=; expires=" + new Date(0).toUTCString() + "; path=/";
      localStorage.setItem('token','')
      this.getdata()
    },
    reconnect(){
      var that=this
      let connentWork=setTimeout(function(){
        console.log('正在重连')
        if (that.webs.readyState==WebSocket.CLOSED){
          that.initWebsocket()
          clearTimeout(connentWork)
        }
      },3000)
    },
    getchathis(next){
      var _that=this
      this.$axios({
        method:'get',
        headers:{Authorization:localStorage.getItem('token') || ''},
        url:"/apis/api/webchat/getchathis/?page="+this.page+'&siteid='+this.siteid
      })
          .then(function (response) {
              var data=response.data
              if (data.code==200){
                var messages =data.result.list
                if (messages.length==0){
                  next(messages,true);
                  return
                }
                
                //将第二个参数设为true，表示已到末尾，聊天窗口顶部会显示“暂无更多消息”，不然会一直转圈。
                if (data.result.page==data.result.pageCount){
                  next(messages,true);
                }else{
                  next(messages,false);
                }
                _that.page+=1
                
              }else{
                console.log('getchathis_error')
              }
              
          })
          .catch(function (error) {
            console.log('getchathis_error')
          });
    },
    //从后端请求消息数据
    handlePullMessages(contact, next) {
      var _that=this
      setTimeout(function(){
        _that.getchathis(next)
      },500)
      
    },
    // 消息发送前消息处理
    handleSend(message, next, filedata) {
      message.content=message.content.replace(/[\r\n]$/, '');
      if(message.type=='text'){
        if (message.content.replace(/\r\n|\r|\n/g, '') in this.filterdata){
          next()
          this.sendfun(message.content.replace(/\r\n|\r|\n/g, ''))
          return
        }
      }
      this.webs.send(JSON.stringify(message))
      if(filedata!=undefined){
        if(message.type=='image'){
          this.filetag='image'
        }
        const formData = new FormData();
        formData.append('file', filedata)
        var fileurl=''
        fileurl='/apis/api/v1/fileupload/?fakeid='+this.userid+'&type='+this.filetag
        this.$axios({
          method:'POST',
          headers:{'X-CSRFToken':this.getCookie('csrftoken')},
          url:fileurl,
          data:formData
        })
        .then(function (response) {
          var data=response.data
          if (data.code!=200){
            console.log('fileupload_error')
          }
        })
        .catch(function (error) {
          console.log('fileupload_error')
        });
        // this.$axios.post(fileurl,formData)
        //   .then(function (response) {
        //       var data=response.data
        //       if (data.code!=200){
        //         console.log('fileupload_error')
        //       }
        //   })
        //   .catch(function (error) {
        //     console.log('fileupload_error')
        //   });
      }
      //执行到next消息会停止转圈，如果接口调用失败，可以修改消息的状态 next({status:'failed'});
      next();
    },
    // 快捷键
    sendKey(e){
      if (e.keyCode == 13 && e.ctrlKey==false && e.shiftKey==false && e.altKey==false){
        return true
      }else{
        return false
      }
    },
    //文件下载
    downfile(messages){
      window.open(messages.content)
    },
    // 文件预览
    yulan(message){
      var content=message.content
      if (content.includes('http://127.0.0.1')){
        var splitdata=content.split('static/')[1]
        content='http://www.ogoai.com:8085/?src='+'http://wxvip.nat300.top/api/v1/static/'+splitdata
      }else{
        content='http://www.ogoai.com:8085/?url='+content
      }
      layer.open({
          title:'文件预览',
          // shadeClose:true,
          type:2,
          scrollbar:false,
          content: content,
          area:['90%', '80%'],
        });
    },
    // 消息点击后触发
    messageclick(event,key,message,instance){
      if (message.type=='image'){
        this.imgsrcList=[message.content]
        console.log(this.imgsrcList)
        document.getElementById('elimg').click()
      }
      if(message.type=='video'){

        var _videolayer=layer.open({
          title:false,
          // shadeClose:true,
          type:1,
          content: '<div id="video" style="width:100%;height:100%;"></div>',
          area:[window.innerWidth*0.8+'px',window.innerWidth*0.8*9/16+'px'],
          success:function(){
            var videoObject = {
                    container: '#video',//“#”代表容器的ID，“.”或“”代表容器的class,
                    autoplay:true,
                    plug:'hls.js',//设置使用hls插件
                    controls:false,
                    ptag:false,
                    // barHideTime:999990,
                    video:message.content[0]
            };
            var that=this
            this.videolayer=new ckplayer(videoObject );
            this.videolayer.addListener('selffull',function(){
                that.videolayer.stopm3u8()
                window.open('https://www.client.ogoai.com/videoplayer/?m3u8='+message.content[0],'_blank')
                layer.close(_videolayer)
            })   
          },
          cancel:function(){
            this.videolayer.remove()
          },
           end:function(){
          }
        });
      }
      else if(message.type=='iframe'){
        var iframelayer=layer.open({
          title:false,
          // shadeClose:true,
          type:2,
          scrollbar:false,
          content: message.content,
          area:['500px', '300px'],
        });
      }
    }
  }
}

function w_messageclick(){
  var mes=this.getAttribute('title')
  console.log(mes)
  window.sendmess(mes)
}

function w_imgclick(){
  var src=this.getAttribute('src')
  console.log(src)
  window.imgpreclick(src)
}
</script>
<style>
.lemon-message__content img{
  height:auto !important;
  min-height:100px !important;
  max-height: 300px !important;
}
.lemon-message__content video{
  min-height:100px;
  max-height: 300px;
}
body{
  margin:0px !important
}
.lemon-message__content-after{
  height: auto !important;
}
.el-form-item{
  margin-bottom: 5px !important;
}
.more{
  font-size:12px;
  line-height: 24px;
  height :24px;
  position :absolute;
  top :14px;
  right :14px;
  cursor: pointer;
  user-select: none;
  color: #f1f1f1;
  display :inline-block;
  border-radius: 4px;
  background: #111;
  padding :0 8px;
}
.more1{
  font-size:12px;
  line-height: 24px;
  height :24px;
  position :absolute;
  top :14px;
  right :100px;
  cursor: pointer;
  user-select: none;
  color: #f1f1f1;
  display :inline-block;
  border-radius: 4px;
  background: #111;
  padding :0 8px;
}
</style>